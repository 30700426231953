<template>
    <div class="container">
        <div class="container__header">
            Custom View
            <mf-button pattern="primary-outline" size="small" @click="toggleSettings">
                <mf-icon slot="icon" icon-name="icon-list" />
                Customize Widgets
            </mf-button>
        </div>
        <template v-for="portcallComponent in activePortcallComponents">
            <div class="container__row" :key="portcallComponent.name">
                <div class="container__item">
                    <vessel-info v-if="'vessel-info' === portcallComponent.componentName" is-custom-page />
                    <stages-box
                        v-else-if="'stages-box' === portcallComponent.componentName"
                        without-all-options-checkbox
                        :options="portcallFilters.stage"
                    />
                    <time-box
                        v-else-if="'time-box' === portcallComponent.componentName"
                        without-all-options-checkbox
                        :options="portcallFilters.time"
                        @afterChanged="portcallFilters.time.before = $event"
                        @beforeChanged="portcallFilters.time.after = $event"
                    />
                    <component v-else :is="portcallComponent.componentName" />
                </div>
            </div>
        </template>

        <template v-for="quayComponent in activeQuayComponents">
            <div class="container__row" :key="quayComponent.name">
                <div class="container__item">
                    <quay-info v-if="'quay-info' === quayComponent.componentName" is-custom-page />
                    <component v-else :is="quayComponent.componentName" />
                </div>
            </div>
        </template>

        <div v-if="portStatistic" :key="currentPortStatisticKeyValue">
            <template v-for="portComponent in activePortComponents">
                <div class="container__row" :key="portComponent.name">
                    <div class="container__item">
                        <component
                            :is="portComponent.componentName"
                            :options="portStatistic[portComponent.componentOptions]"
                            :time="portStatisticTimeFilter"
                        />
                    </div>
                </div>
            </template>
        </div>

        <template v-for="additionComponent in activeAdditionComponents">
            <div class="container__row" :key="additionComponent.name">
                <div class="container__item">
                    <component :is="additionComponent.componentName" />
                </div>
            </div>
        </template>

        <widgets-modal :is-open="isSettingsOpen" @close="toggleSettings" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { camelCase, kebabCase, debounce } from 'lodash';

    import Chat from '@/components/Chat';

    import PortOverallStage from '@/components/port-components/PortOverallStage';
    import PortOverallWorkload from '@/components/port-components/PortOverallWorkload';
    import PortOverallStatus from '@/components/port-components/PortOverallStatus';

    import VesselTraffic from '@/components/vessel-components/VesselTraffic';
    import StatementLog from '@/components/statement-components/StatementLog';

    import QuayTimeline from '@/components/quay-components/QuayTimeline';
    import QuayLocation from '@/components/quay-components/QuayLocation';

    import PortcallTimeline from '@/components/port-call-components/PortcallTimeline';
    import PortcallStates from '@/components/port-call-components/PortcallStates';
    import PortcallLocations from '@/components/port-call-components/PortcallLocations';
    import PortcallDemurrageTimeline from '@/components/port-call-components/PortcallDemurrageTimeline';

    import PortToPort from '@/components/port-components/PortToPort';
    import VivaWeather from '@/components/VivaWeather';
    import VesselTracker from '@/components/vessel-components/VesselTracker';
    import PortcallMultiview from '@/components/port-call-components/PortcallMultiview';
    import StagesBox from '@/modals/filter-modal-components/StagesBox';
    import TimeBox from '@/modals/filter-modal-components/TimeBox';
    import VesselsAtQuay from '@/components/vessel-components/VesselsAtQuay';
    import WidgetsModal from '@/modals/WidgetsModal';
    import VesselInfo from '@/components/vessel-components/VesselInfo';
    import QuayInfo from '@/components/quay-components/QuayInfo';
    import QuayMultiviewTimeline from '@/components/quay-components/QuayMultiview';

    const CUSTOM_QUAY_ELEMENTS_NAMES = {
        'Quay List': 'quay-info',
        'Quay Status': 'vessels-at-quay',
    };

    const CUSTOM_PORTCALL_ELEMENTS_NAMES = {
        'Portcall List': 'vessel-info',
        'Stage Completion': 'stages-box',
        'Time Progress': 'time-box',
    };

    export default {
        name: 'CustomPreview',
        components: {
            QuayMultiviewTimeline,
            QuayInfo,
            VesselInfo,
            WidgetsModal,
            VesselsAtQuay,
            TimeBox,
            StagesBox,
            PortcallMultiview,
            VesselTracker,
            VivaWeather,
            PortToPort,
            PortcallLocations,
            PortcallStates,
            PortcallTimeline,
            PortcallDemurrageTimeline,
            QuayLocation,
            QuayTimeline,
            StatementLog,
            VesselTraffic,
            PortOverallStatus,
            PortOverallWorkload,
            PortOverallStage,
            Chat,
        },
        mounted() {
            this.portStatisticTimeFilter = this.setDefaultTimeValues();
            this.$store.dispatch('getPortStatistic', this.portStatisticTimeFilter);
        },
        computed: {
            ...mapGetters(['portStatistic', 'portcallFilters', 'customLayout']),
            activePortComponents() {
                return this.customLayout.port
                    .filter(portElement => portElement.checked)
                    .map(portElement => {
                        portElement.componentName = kebabCase(portElement.name);
                        portElement.componentOptions = camelCase(portElement.name.replace('Port ', ''));
                        return portElement;
                    });
            },
            activeAdditionComponents() {
                return this.customLayout.addition
                    .filter(additionElement => additionElement.checked)
                    .map(additionElement => {
                        additionElement.componentName = kebabCase(additionElement.name);
                        return additionElement;
                    });
            },
            activeQuayComponents() {
                return this.customLayout.quay
                    .filter(quayElement => quayElement.checked)
                    .map(quayElement => {
                        quayElement.componentName =
                            CUSTOM_QUAY_ELEMENTS_NAMES[quayElement.name] || kebabCase(quayElement.name);
                        return quayElement;
                    });
            },
            activePortcallComponents() {
                return this.customLayout.portcall
                    .filter(portcallElement => {
                        return portcallElement.checked;
                    })
                    .map(portcallElement => {
                        portcallElement.componentName =
                            CUSTOM_PORTCALL_ELEMENTS_NAMES[portcallElement.name] || kebabCase(portcallElement.name);
                        return portcallElement;
                    });
            },
        },
        watch: {
            'portcallFilters.stage': {
                handler() {
                    this.$store.dispatch('getPortCalls');
                },
                deep: true,
            },
            'portStatisticTimeFilter.after'(val) {
                val && this.getPortStatistics();
            },
            'portStatisticTimeFilter.before'(val) {
                val && this.getPortStatistics();
            },
            portStatistic(val) {
                if (val) {
                    this.currentPortStatisticKeyValue =
                        this.portStatisticTimeFilter.after + this.portStatisticTimeFilter.before;
                }
            },
        },
        data() {
            return {
                isSettingsOpen: false,
                portStatisticTimeFilter: {
                    after: '',
                    before: '',
                },
                currentPortStatisticKeyValue: '',
            };
        },
        methods: {
            getPortStatistics: debounce(function() {
                this.$store.dispatch('getPortStatistic', this.portStatisticTimeFilter);
            }, 500),
            toggleSettings() {
                this.isSettingsOpen = !this.isSettingsOpen;
            },
            setDefaultTimeValues() {
                const addDays = (date, days) => {
                    let result = new Date(date);
                    result.setDate(result.getDate() + days);
                    return result;
                };
                const now = new Date();
                const prev = addDays(now, -6);
                const next = addDays(now, 8);
                return {
                    after: prev,
                    before: next,
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/default/layout.scss';

    .container__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-weight: bold;
        font-size: $font-30;
        line-height: $font-36;
        color: $white;
    }
</style>
