<template>
    <mf-modal v-if="isOpen" @close="close">
        <div slot="header">
            Widgets
        </div>
        <div class="filter__container" v-if="widgetsLayout">
            <div class="filter__column">
                <div class="filter__row">
                    <div class="filter__column">
                        <portcall-elements
                            :options="widgetsLayout.portcall"
                            :all-options-selected="allOptionsSelected('portcall')"
                            @toggleAll="toggleAll('portcall')"
                        />
                    </div>
                    <div class="filter__column">
                        <quay-elements
                            :options="widgetsLayout.quay"
                            :all-options-selected="allOptionsSelected('quay')"
                            @toggleAll="toggleAll('quay')"
                        />
                    </div>
                    <div class="filter__column filter__column_small">
                        <div class="filter__row">
                            <div class="filter__column filter__column_large">
                                <port-elements
                                    :options="widgetsLayout.port"
                                    :all-options-selected="allOptionsSelected('port')"
                                    @toggleAll="toggleAll('port')"
                                />
                            </div>
                            <div class="filter__column filter__column_large">
                                <custom-elements
                                    :options="widgetsLayout.addition"
                                    :all-options-selected="allOptionsSelected('addition')"
                                    @toggleAll="toggleAll('addition')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="filter__buttons">
                <mf-button class="filter__button" @click="setDefaults">Defaults</mf-button>
                <mf-button class="filter__button" color="blue" @click="apply">Apply</mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { cloneDeep } from 'lodash';
    import PortcallElements from '@/modals/custom-view-modal-components/PortcallElements';
    import QuayElements from '@/modals/custom-view-modal-components/QuayElements';
    import PortElements from '@/modals/custom-view-modal-components/PortElements';
    import CustomElements from '@/modals/custom-view-modal-components/CustomElements';

    export default {
        name: 'WidgetsModal',
        components: {
            CustomElements,
            PortElements,
            QuayElements,
            PortcallElements,
        },
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            ...mapGetters(['customLayout']),
        },
        watch: {
            customLayout: {
                handler(val) {
                    this.widgetsLayout = cloneDeep(val);
                },
                immediate: true,
            },
        },
        data() {
            return {
                widgetsLayout: null,
            };
        },
        methods: {
            toggleAll(optionName) {
                const currentState = this.allOptionsSelected(optionName);
                this.widgetsLayout[optionName].map(value => {
                    value instanceof Array
                        ? value.map(innerValue => {
                              innerValue.checked = !currentState;
                              return innerValue;
                          })
                        : (value.checked = !currentState);
                    return value;
                });
            },
            allOptionsSelected(optionName) {
                return this.widgetsLayout[optionName].every(value => {
                    return value instanceof Array ? value.every(innerValue => innerValue.checked) : value.checked;
                });
            },
            close() {
                this.$emit('close');
            },
            setDefaults() {
                this.$store.commit('setDefaultsCustomLayout');
            },
            apply() {
                this.$store.commit('setCustomLayout', this.widgetsLayout);
                this.close();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter__container {
        display: flex;
        margin: (-$space-8) 0 (-$space-8) (-$space-8);
        flex-wrap: wrap;
        @include media($lg) {
            flex-wrap: nowrap;
        }
    }

    .filter__row {
        display: flex;
        margin: (-$space-8);
        flex-wrap: wrap;
    }

    .filter__column {
        flex: 1 1 auto;
        padding: $space-8;
        box-sizing: border-box;
        width: 100%;
        flex-basis: 100%;
        @include media($sm) {
            width: auto;
            flex-basis: auto;
        }
    }

    .filter__column_small {
        @include media($lg) {
            width: 33.33%;
            flex-basis: 33.33%;
        }
    }

    .filter__column_large {
        width: 100%;
        flex-basis: 100%;
    }

    .filter__buttons {
        display: flex;
        justify-content: flex-end;
    }

    .filter__button:not(:first-child) {
        margin-left: $space-8;
    }
</style>
