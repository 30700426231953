<template>
    <mf-box :empty="!portcall || !portToPortInfo">
        <div slot="header">
            Port To Port
        </div>
        <div class="port-to-port__content" :key="isLoading">
            <template v-if="!isLoading">
                <template v-if="portcall && portToPortInfo">
                    <portcall-item class="port-to-port__column port-to-port__left-column" :portcall="portcall" />
                    <div class="port-to-port__column port-to-port__center-column">
                        <div class="port-to-port__block">
                            <div class="port-to-port__block-title">
                                Departure
                            </div>
                            <template v-if="portToPortInfo.departureEvent">
                                <div>
                                    Time:
                                    <span class="port-to-port__value">
                                        {{ portToPortInfo.departureEvent.endTime | dateFilter('date, time') }}
                                    </span>
                                </div>
                                <div>
                                    Port Name:
                                    <span class="port-to-port__value">
                                        {{ portToPortInfo.departureEvent.quayName }}
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <span>
                                    No info
                                </span>
                            </template>
                        </div>
                    </div>
                    <div class="port-to-port__column port-to-port__right-column">
                        <div class="port-to-port__block">
                            <div class="port-to-port__block-title">
                                Arrival
                            </div>
                            <template v-if="portToPortInfo.arrivalEvent">
                                <div>
                                    Time:
                                    <span class="port-to-port__value">
                                        {{ portToPortInfo.arrivalEvent.endTime | dateFilter('date, time') }}
                                    </span>
                                </div>
                                <div>
                                    Port Name:
                                    <span class="port-to-port__value">
                                        {{ portToPortInfo.arrivalEvent.quayName }}
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <span>
                                    No info
                                </span>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
            <div v-else class="port-to-port__loading">
                Wait loading port to port info
            </div>
        </div>
        <div slot="empty-text">
            {{ emptyText }}
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import PortcallItem from '@/components/port-call-components/PortcallItem';
    import portToPortPCM from '@/services/port-call-manager/portToPortPCM';
    import portCallPCM from '@/services/port-call-manager/portCallPCM';
    import formatPortcall from '@/helpers/formatPortcall.helper';
    import addVesselToPortcall from '@/helpers/addVesselToPortcall.helper';
    import { getQuayFromStore } from '@/helpers/getQuayFromState.helper';
    import { isEmpty } from 'lodash';

    export default {
        name: 'PortToPort',
        components: { PortcallItem },
        data() {
            return {
                portcall: null,
                portToPortInfo: {},
                isLoading: false,
            };
        },
        watch: {
            selectedPortCallId: {
                handler(val) {
                    if (val) {
                        this.getPortToPortInfo(val);
                    }
                },
                immediate: true,
            },
        },
        computed: {
            ...mapGetters(['selectedPortCallId']),
            emptyText() {
                return this.selectedPortCallId
                    ? 'Portcall does not contain any Port-to-Port data'
                    : 'No portcall chosen';
            },
        },
        methods: {
            async getPortToPortInfo(portcallId) {
                this.isLoading = true;

                let portcallResponse = await portCallPCM.show(portcallId);
                let portcall = await formatPortcall(portcallResponse.data);
                this.portcall = await addVesselToPortcall(portcall);

                const portToPortResponse = await portToPortPCM.show(portcallId);
                this.portToPortInfo = portToPortResponse.data[0];
                isEmpty(this.portToPortInfo) && (this.portToPortInfo = null);

                if (this.portToPortInfo) {
                    if (this.portToPortInfo.arrivalEvent) {
                        this.portToPortInfo.arrivalEvent.quayName = await this.getPortName(
                            this.portToPortInfo.arrivalEvent
                        );
                    }
                    if (this.portToPortInfo.departureEvent) {
                        this.portToPortInfo.departureEvent.quayName = await this.getPortName(
                            this.portToPortInfo.departureEvent
                        );
                    }
                }

                this.isLoading = false;
            },
            async getPortName(event) {
                if (event.at) {
                    const quay = await getQuayFromStore(event.at);
                    return quay.name;
                }
                if (event.to) {
                    const quay = await getQuayFromStore(event.to);
                    return quay.name;
                }
                if (event.from) {
                    const quay = await getQuayFromStore(event.from);
                    return quay.name;
                }
                return 'N/A';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .port-to-port__content {
        display: flex;
        column-count: 3;
    }

    .port-to-port__column {
        width: 100%;
        display: flex;
    }

    .port-to-port__left-column {
        height: 100%;
    }

    .port-to-port__center-column,
    .port-to-port__right-column {
        padding: 16px 16px 0;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: $white;
        font-size: $font-14;
        line-height: $font-24;
        width: 80%;
        @include media($xlg) {
            width: 100%;
        }
    }

    .port-to-port__block-title {
        font-weight: bold;
        margin-bottom: $space-8;
    }

    .port-to-port__value {
        font-weight: bold;
    }

    .port-to-port__loading {
        display: flex;
        justify-content: center;
        color: white;
    }
</style>
