<template>
    <mf-box>
        <div slot="header">
            Viva Weather
        </div>
        <div class="viva-weather">
            <div class="viva-weather__header">
                <div class="viva-weather__items">
                    <div
                        class="viva-weather__item"
                        :class="{ 'viva-weather__item_selected': isSettingsShown }"
                        @click="toggleSettings"
                    >
                        <mf-icon class="viva-weather__icon" icon-name="icon-filter" />
                        <div class="viva-weather__text">Settings</div>
                    </div>
                </div>
            </div>
            <mf-divider />
            <mf-responsive-table v-if="weatherCategories">
                <table class="viva-weather__table">
                    <tr class="viva-weather__labels">
                        <th class="viva-weather__th"></th>
                        <th class="viva-weather__th" v-for="stationName in stationsName" :key="stationName">
                            <div class="viva-weather__label">
                                {{ stationName }}
                            </div>
                        </th>
                    </tr>
                    <mf-divider />
                    <template v-for="(category, index) in displayedCategories">
                        <tr
                            class="viva-weather__line"
                            :key="category.title"
                            :class="index % 2 && 'viva-weather__line_light'"
                        >
                            <td class="viva-weather__th">
                                <div class="viva-weather__title">{{ category.title }} ({{ category.unit }})</div>
                            </td>
                            <td
                                class="viva-weather__th"
                                v-for="stationName in stationsName"
                                :key="`value-${stationName}`"
                            >
                                <div class="viva-weather__value">
                                    <div>
                                        {{ formatValue(category.stations[stationName], 'Value') }}
                                    </div>
                                    <div v-if="isWindDirectionExist(category, stationName)">
                                        {{ windValue(category.stations[stationName]) }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <mf-divider :key="`divider-${category.title}`" />
                    </template>
                </table>
            </mf-responsive-table>
        </div>
        <weather-modal :is-open="isSettingsShown" @close="toggleSettings" />
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import vivaStationsApi from '@/services/viva-stations/vivaStationsApi';
    import MfDivider from '@/components/default/MfDivider';
    import WeatherModal from '@/modals/WeatherModal';
    const COMPASS_VALUES = [
        'N',
        'NNE',
        'NE',
        'ENE',
        'E',
        'ESE',
        'SE',
        'SSE',
        'S',
        'SSW',
        'SW',
        'WSW',
        'W',
        'WNW',
        'NW',
        'NNW',
    ];

    const VISIBLE_WEATHER_VALUES = ['Byvind', 'Medelvind', 'Vattenstånd', 'Vattentemperatur', 'Lufttemperatur', 'Sikt'];

    export default {
        name: 'VivaWeather',
        components: { WeatherModal, MfDivider },
        computed: {
            ...mapGetters(['vivaStations']),
            stationsName() {
                return this.vivaStations.map(station => station.Name);
            },
            displayedCategories() {
                return Object.values(this.weatherCategories).filter(category => category.showInfo);
            },
        },
        watch: {
            vivaStations: {
                async handler(val) {
                    val.forEach(this.updateInfoAboutStation);
                },
                immediate: true,
            },
        },
        data() {
            return {
                weatherCategories: {},
                isSettingsShown: false,
            };
        },
        methods: {
            async updateInfoAboutStation(station) {
                const response = await vivaStationsApi.show(station.ID);
                const data = response.data.GetSingleStationResult.Samples;
                data.forEach(value => this.updateCategoriesInfo(value, station));
            },
            updateCategoriesInfo(value, station) {
                if (!this.weatherCategories[value.Name]) {
                    this.$set(this.weatherCategories, value.Name, {
                        id: value.StationID,
                        title: value.Name,
                        unit: value.Unit,
                        showInfo: VISIBLE_WEATHER_VALUES.includes(value.Name),
                        stations: {},
                    });
                }
                this.weatherCategories[value.Name].stations[station.Name] = this.getWindDir(value);
            },
            degreesToCompass(degrees) {
                const compassValue = Math.floor(degrees / 22.5 + 0.5);
                return COMPASS_VALUES[compassValue % 16];
            },
            getWindDir(weather) {
                weather.windDir = this.degreesToCompass(weather.Heading);
                weather.Heading = Math.round(weather.Heading);
                return weather;
            },
            formatValue(object, key) {
                return object && object[key] ? object[key] : '-';
            },
            windValue(object) {
                return `${object.Heading}° (${object.windDir})`;
            },
            isWindDirectionExist(category, stationName) {
                return (
                    category.title === 'Byvind' &&
                    category.stations[stationName] &&
                    category.stations[stationName].Heading &&
                    category.stations[stationName].windDir
                );
            },
            toggleSettings() {
                this.isSettingsShown = !this.isSettingsShown;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .viva-weather__table {
        width: 100%;
    }

    .viva-weather__header {
        margin-bottom: $space-20;
        display: flex;
        justify-content: flex-end;
    }

    .viva-weather__items {
        display: flex;
        align-items: center;
    }

    .viva-weather__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $white;
        font-size: $font-12;
        line-height: $font-14;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
        &:not(:first-child) {
            margin-left: $space-16;
        }
    }

    .viva-weather__item_selected {
        color: $blue-300;
    }

    .viva-weather__item_disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    .viva-weather__icon {
        margin-bottom: $space-8;
    }

    .viva-weather__table {
        margin-top: $space-20;
        padding-bottom: $space-4;
        position: relative;
        th,
        tr,
        td {
            padding: 0;
        }
    }

    .viva-weather__title {
        font-weight: bold;
        font-size: $font-13;
        line-height: $font-18;
        display: flex;
        align-items: center;
        color: $white;
        opacity: 0.8;
        min-width: 170px;
        padding: $space-8;
    }

    .viva-weather__value {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .viva-weather__line {
        display: flex;
    }

    .viva-weather__line_light {
        background: $marine-600;
    }

    .viva-weather__labels {
        display: flex;
    }

    .viva-weather__th {
        display: flex;
        align-items: stretch;
        min-width: 120px;
        color: $white;
        position: relative;
    }

    .viva-weather__th:first-child {
        min-width: 170px;
        flex: 0;
    }

    .viva-weather__label {
        font-size: $font-12;
        line-height: $font-18;
        border: 1px solid $marine-500;
        padding: 0 5px;
        border-top: transparent;
        height: 16px;
        flex: 1 1 auto;
    }
</style>
