import formatPortcall from '@/helpers/formatPortcall.helper';
import addVesselToPortcall from '@/helpers/addVesselToPortcall.helper';
import portCallPCM from '@/services/port-call-manager/portCallPCM';
import formatEvent from '@/helpers/formatEvent.helper';

export default async function getInfoAboutPortcall(portcallId) {
    const response = await portCallPCM.show(portcallId);
    let portcall = await formatPortcall(response.data);
    portcall = await addVesselToPortcall(portcall);
    const eventsResponse = await portCallPCM.events(portcallId);
    let eventsFromResponse = [...eventsResponse.data.events, ...eventsResponse.data.carmentaEvents];

    portcall.events = (
        await Promise.all(
            eventsFromResponse.map(event => {
                event.style = { width: '100%' };
                event.showTime = true;
                return formatEvent(event, false);
            })
        )
    ).filter(event => !event.isExpired && !event.isCancelled && !event.isDenied);

    return portcall;
}
