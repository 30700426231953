<template>
    <div class="portcall-item" :class="{ 'portcall-item_active': isSelected }" @click="selectPortcall">
        <vessel-image :portcall="portcall" small />
        <div class="portcall-item__info">
            <div class="info__item portcall-item__header">
                {{ portcall.vessel.name }}
            </div>
            <div
                class="info__item portcall-item__date"
                v-if="!!(Date.parse(portcall.startTime) && Date.parse(portcall.endTime))"
            >
                {{ portcall.startTime | dateFilter('portCall') }}
                <mf-icon class="portcall-item__icon" icon-name="icon-arrow" height="10" width="10" />
                {{ portcall.endTime | dateFilter('portCall') }}
            </div>
            <div v-if="portcall.lastUpdatedState" class="info__item portcall-item__state">
                <statement-icon class="portcall-item__short-type" :type="portcall.timeType" size="small" />
                {{ portcall.lastUpdatedState }}
            </div>
            <div class="info__item info__item_empty" v-else></div>
        </div>
        <div class="portcall-item__status">
            <div class="portcall-item__circle" :class="`portcall-item__circle_${portcall.status}`"></div>
            <div class="portcall-item__status-info">
                {{ portcall.stage }}
            </div>
        </div>
    </div>
</template>

<script>
    import StatementIcon from '@/components/statement-components/StatementIcon';
    import VesselImage from '@/components/vessel-components/VesselImage';
    import { mapGetters } from 'vuex';

    export default {
        components: { VesselImage, StatementIcon },
        props: {
            portcall: {
                type: Object,
                required: true,
            },
            isSelected: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapGetters(['selectedPortCallId']),
        },
        methods: {
            selectPortcall() {
                if (this.selectedPortCallId !== this.portcall.portCallId) {
                    this.$store.commit('setSelectedPortCall', this.portcall);
                    this.$router.push({ name: 'portcall', params: { id: this.portcall.portCallId } });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .portcall-item {
        padding: $space-16 0 $space-16 $space-16;
        display: flex;
        border-right: 3px solid transparent;
        cursor: pointer;
        position: relative;
    }

    .portcall-item_active {
        background: $marine-600;
        border-right: 3px solid $blue-500;
    }

    .portcall-item__info {
        margin: (-$space-4) 0 (-$space-4) $space-16;
    }

    .info__item {
        padding: $space-4 0;
    }

    .info__item_empty {
        height: 22px;
    }

    .portcall-item__photo {
        position: relative;
        height: fit-content;
    }

    .portcall-item__image {
        height: 60px;
        width: 60px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .portcall-item__favorite {
        position: absolute;
        bottom: -2px;
        right: -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        width: 18px;
        background: $marine-400;
        border-radius: 60px;
        color: $white;
        svg {
            margin-bottom: 0;
        }
    }

    .portcall-item__favorite_active {
        background: $green-700;
    }

    .portcall-item__header {
        font-weight: 600;
        font-size: $font-18;
        line-height: $font-20;
        color: $white;
    }

    .portcall-item__date {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: $font-14;
        line-height: $font-18;
        color: $marine-300;
    }

    .portcall-item__icon {
        margin: 0 0.5em;
    }

    .portcall-item__state {
        position: relative;

        font-weight: bold;
        font-size: $font-12;
        line-height: $font-14;
        color: $marine-300;
        padding-left: $space-24;
    }

    .portcall-item__short-type {
        position: absolute;
        left: 0;
        bottom: 2px;
    }

    .portcall-item__status {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 6px;
        right: $space-12;
        height: $font-14;
        transform: rotate(-90deg);
        transform-origin: right bottom;
    }

    .portcall-item__circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    .portcall-item__circle_check {
        background: $green-500;
    }

    .portcall-item__circle_warning {
        background: $orange-500;
    }

    .portcall-item__circle_error {
        background: $red-500;
    }

    .portcall-item__circle_empty {
        background: $blue-500;
    }

    .portcall-item__status-info {
        margin-left: $space-4;
        font-weight: normal;
        font-size: $font-12;
        line-height: $font-14;
        color: $white;
    }
</style>
