<template>
    <gmap-map :options="mapOptions" class="vessel-tracker__map" :center="{ lat: 0, lng: 0 }" ref="map">
        <gmap-polyline class="vessel-tracker__polyline" ref="polyline" :options="polylineOptions" />
        <gmap-marker class="vessel-tracker__marker" ref="marker" :options="markerOptions" />
    </gmap-map>
</template>

<script>
    import mapStyles from '@/config/mapStyles';

    export default {
        name: 'VesselPosition',
        async mounted() {
            await this.$gmapApiPromiseLazy();
            // eslint-disable-next-line no-undef
            let bounds = new google.maps.LatLngBounds();
            this.coordinatesInTimeOrder.forEach(coordinate => bounds.extend(coordinate));
            this.$refs.map.fitBounds(bounds);
            this.$refs.map.panToBounds(bounds, 20);
        },
        computed: {
            polylineOptions() {
                return {
                    path: this.coordinatesInTimeOrder,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                };
            },
            markerOptions() {
                return {
                    position: this.coordinatesInTimeOrder[this.coordinatesInTimeOrder.length - 1],
                };
            },

            polygonOptions() {
                return {
                    paths: this.coordinatesInTimeOrder,
                    strokeColor: '#B8EFF1',
                    fillColor: '#495678',
                };
            },
        },
        data() {
            return {
                mapOptions: {
                    styles: mapStyles,
                    disableDefaultUI: true,
                    zoomControl: true,
                },
                // MOCKED TRACK FROM OLD REPOSITORY
                coordinatesInTimeOrder: [
                    { lat: 57.713531, lng: 11.956671 },
                    { lat: 57.313531, lng: 11.556671 },
                    { lat: 57.313531, lng: 11.656671 },
                    { lat: 57.513531, lng: 11.756671 },
                    { lat: 57.313531, lng: 11.856671 },
                ],
            };
        },
    };
</script>

<style scoped></style>
