<template>
    <mf-box :border-box="!withoutAllOptionsCheckbox" without-inner-paddings>
        <template slot="header">
            <mf-checkbox
                v-if="!withoutAllOptionsCheckbox"
                class="filter__checkbox"
                @change="options.filterByTime = $event"
                :checked="options.filterByTime"
            >
                Time
            </mf-checkbox>
            <div v-else class="filter__checkbox">
                Time progress
            </div>
        </template>

        <mf-divider />
        <div class="time-filter__box">
            <div class="time-filter__item time-filter__item_line">
                After:
                <mf-datepicker
                    class="time-filter__datepicker"
                    :calendar-class="'mf-datepicker__calendar_right'"
                    v-model="options.after"
                    calendarClass="mf-datepicker__calendar_right"
                    full-month-name
                    calendar-button
                    :disabled-dates="{ from: options.before }"
                    required
                />
            </div>
            <div class="time-filter__item time-filter__item_line">
                Before:
                <mf-datepicker
                    class="time-filter__datepicker"
                    :calendar-class="'mf-datepicker__calendar_right'"
                    v-model="options.before"
                    :disabled-dates="{ to: options.after }"
                    calendarClass="mf-datepicker__calendar_right"
                    full-month-name
                    calendar-button
                    required
                />
            </div>
            <div class="time-filter__item">
                <span v-html="defineTimeIntervalLabel(intervalStart)" />
                <mf-icon class="time-filter__icon" icon-name="icon-arrow" height="10" width="10" />
                <span v-html="defineTimeIntervalLabel(intervalEnd)" />.
            </div>
            <mf-checkbox class="filter__checkbox" @change="options.useInterval = $event" :checked="options.useInterval">
                Keep this time interval
            </mf-checkbox>
        </div>
    </mf-box>
</template>

<script>
    const MILLISECONDS_IN_MINUTE = 60000;
    const MILLISECONDS_IN_DAY = MILLISECONDS_IN_MINUTE * 60 * 24;

    export default {
        name: 'TimeBox',
        props: {
            options: {
                type: Object,
                required: true,
            },
            withoutAllOptionsCheckbox: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            intervalStart() {
                return Math.floor((this.options.after.getTime() - new Date().getTime()) / MILLISECONDS_IN_DAY);
            },
            intervalEnd() {
                return Math.floor((this.options.before.getTime() - new Date().getTime()) / MILLISECONDS_IN_DAY);
            },
        },
        watch: {
            'options.after'(newVal, oldVal) {
                if (this.options.useInterval && !this.eventFiredByAnotherWatch) {
                    this.$emit(
                        'afterChanged',
                        this.addDays(new Date(newVal), this.intervalDiff(oldVal, this.options.before))
                    );
                    this.eventFiredByAnotherWatch = true;
                } else {
                    this.eventFiredByAnotherWatch = false;
                }
            },
            'options.before'(newVal, oldVal) {
                if (this.options.useInterval && !this.eventFiredByAnotherWatch) {
                    this.$emit(
                        'beforeChanged',
                        this.addDays(new Date(newVal), -this.intervalDiff(oldVal, this.options.after))
                    );
                    this.eventFiredByAnotherWatch = true;
                } else {
                    this.eventFiredByAnotherWatch = false;
                }
            },
        },
        data() {
            return {
                eventFiredByAnotherWatch: false,
            };
        },
        methods: {
            intervalDiff(start, end) {
                return Math.floor((end.getTime() - start.getTime()) / MILLISECONDS_IN_DAY);
            },
            defineTimeIntervalLabel(days) {
                return `<b>${Math.abs(days)}</b> ${days > 0 ? 'day(s) forward' : 'day(s) back'}`;
            },
            addDays(date, days) {
                let result = new Date(date);
                result.setDate(result.getDate() + days);
                return result;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .time-filter__box {
        padding: $space-20 $space-16;
        font-weight: normal;
        font-size: $font-14;
        line-height: $font-24;
        color: $white;
    }

    .time-filter__item {
        display: flex;
        align-items: center;
    }

    .time-filter__item_line {
        justify-content: space-between;
    }

    .time-filter__item:not(:last-child) {
        margin-bottom: 16px;
    }

    .time-filter__datepicker {
        margin-left: $space-12;
        max-width: 160px;
    }

    .time-filter__icon {
        margin: 0 0.5em;
    }
</style>
