<template>
    <mf-box border-box without-inner-paddings>
        <mf-checkbox slot="header" class="filter__checkbox" @change="toggleAll" :checked="allOptionsSelected">
            Port elements
        </mf-checkbox>
        <div>
            <template v-for="status in options">
                <mf-divider :key="`divider-${status.name}`" />
                <div class="filter__item" :key="status.name">
                    <mf-checkbox class="filter__checkbox" @change="status.checked = $event" :checked="status.checked">
                        <span class="status__icon" :class="`status__icon_${status.color}`"></span>
                        {{ status.name }}
                    </mf-checkbox>
                </div>
            </template>
        </div>
    </mf-box>
</template>

<script>
    export default {
        name: 'PortElements',
        props: {
            options: {
                type: Array,
                required: true,
            },
            allOptionsSelected: {
                type: Boolean,
                default: false,
            },
            withoutAllOptionsCheckbox: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            toggleAll() {
                this.$emit('toggleAll');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter__item {
        padding: $space-14 $space-16;
    }
</style>
