<template>
    <mf-box :empty="!selectedPortCallId" without-inner-paddings>
        <div slot="header">
            Vessel Tracker
        </div>
        <vessel-position v-if="selectedPortCallId" />
        <div slot="empty-text">
            No portcall chosen
        </div>
    </mf-box>
</template>

<script>
    import VesselPosition from '@/components/vessel-components/VesselPosition';
    import { mapGetters } from 'vuex';

    export default {
        name: 'VesselTracker',
        components: { VesselPosition },
        computed: {
            ...mapGetters(['selectedPortCallId']),
        },
    };
</script>

<style lang="scss" scoped>
    .vessel-tracker__map {
        height: 252px;
        width: 100%;
    }
</style>
