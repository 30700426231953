<template>
    <mf-modal small :scrollable="false" v-if="isOpen" @close="close">
        <div slot="header">
            Viva weather settings
        </div>
        <div class="weather-modal">
            <mf-select
                class="weather-modal__select"
                placeholder="Add a station"
                :options="filteredStations"
                label="Name"
                @select="handleStationSelect"
            >
                <div slot="caret">
                    <mf-icon class="multiselect__select" icon-name="icon-down" />
                </div>
            </mf-select>
            <div class="weather-modal__item" v-for="station in vivaStations" :key="station.Name">
                {{ station.Name }}
                <mf-button size="x-small" @click="removeStation(station)">Remove</mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import vivaStationsApi from '@/services/viva-stations/vivaStationsApi';

    export default {
        name: 'WeatherModal',
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            ...mapGetters(['vivaStations']),
            filteredStations() {
                return this.stations.filter(station => !this.userVivaStationsIds.includes(station.ID));
            },
            userVivaStationsIds() {
                return this.vivaStations.map(station => station.ID);
            },
        },
        async mounted() {
            const response = await vivaStationsApi.index();
            this.stations = response.data.GetStationsResult.Stations;
        },
        data() {
            return {
                stations: null,
            };
        },
        methods: {
            close() {
                this.$emit('close');
            },
            handleStationSelect(station) {
                this.$store.commit('addVivaStation', station);
            },
            removeStation(station) {
                this.$store.commit('removeVivaStation', station.ID);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .weather-modal {
        min-height: 400px;
    }

    .weather-modal__select {
        margin-bottom: $space-16;
    }

    .weather-modal__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: $space-8 $space-16;
        &:not(:last-child) {
            margin-bottom: $space-8;
        }
        font-weight: 600;
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;
    }
</style>
