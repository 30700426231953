<template>
    <mf-box>
        <div class="port-overall__header" slot="header">
            Port Overall Workload
            <div class="port-overall__time-filter-box">
                <div class="time-filter__item">
                    <mf-datepicker
                        class="time-filter__datepicker"
                        v-model="time.after"
                        :calendarClass="'mf-datepicker__calendar_right'"
                        full-month-name
                        calendar-button
                        :disabled-dates="{ from: time.before }"
                        required
                    />
                </div>
                <div class="time-filter__item">
                    <mf-datepicker
                        class="time-filter__datepicker"
                        v-model="time.before"
                        :calendarClass="'mf-datepicker__calendar_right'"
                        :disabled-dates="{ to: time.after }"
                        full-month-name
                        calendar-button
                        required
                    />
                </div>
            </div>
        </div>
        <div>
            <Highcharts class="port-overall__chart" :options="highchartsOptions" />
        </div>
    </mf-box>
</template>

<script>
    export default {
        name: 'PortOverallWorkload',
        props: {
            options: {
                type: Array,
                required: true,
            },
            time: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                loaded: true,
                highchartsOptions: {
                    chart: {
                        type: 'column',
                        styledMode: true,
                    },
                    tooltip: {
                        headerFormat: '',
                        useHTML: true,
                        pointFormatter: function() {
                            return `
                                <div class="tooltip__header">${this.name}</div>
                                <div class="tooltip__value"><b>${this.y}</b> vessels in port</div>
                            `;
                        },
                    },
                    title: '',
                    legend: { enabled: false },
                    xAxis: {
                        type: 'category',
                        tickWidth: 1,
                        tickLength: 18,
                        labels: { useHTML: true },
                    },
                    yAxis: {
                        labels: { useHTML: true },
                        title: { text: '' },
                        allowDecimals: false,
                    },
                    series: [{ data: this.options }],
                },
            };
        },
    };
</script>

<style lang="scss" scoped>
    .port-overall__header {
        display: flex;
        justify-content: space-between;
    }

    .port-overall__time-filter-box {
        display: flex;
        align-items: center;
    }

    .time-filter__item:not(:last-child) {
        margin-right: $space-16;
    }

    .port-overall__chart {
        height: 280px;
    }

    /deep/ {
        .highcharts-container {
            position: relative;
        }

        .highcharts-background,
        .highcharts-plot-background,
        .highcharts-plot-border,
        .highcharts-legend-box {
            fill: transparent;
        }

        .highcharts-axis-labels span {
            font-family: Proxima Nova, serif;
            font-style: normal;
            font-weight: normal;
            font-size: $font-12 !important;
        }

        .highcharts-yaxis-labels span {
            line-height: $font-14 !important;
            color: $marine-300 !important;
        }

        .highcharts-xaxis-labels {
            top: -7px !important;
        }

        .highcharts-xaxis-labels span {
            line-height: $font-18 !important;
            color: $white !important;
        }

        .highcharts-color-0 {
            fill: $blue-500;
            stroke: transparent;
        }

        .highcharts-credits {
            display: none;
        }

        .highcharts-grid-line,
        .highcharts-axis-line,
        .highcharts-tick {
            stroke: $marine-600;
        }

        .highcharts-xaxis-grid,
        .highcharts-yaxis {
            display: none;
        }

        .highcharts-tooltip-box {
            fill: $marine-600;
            stroke: transparent;
        }

        .tooltip__header {
            font-family: Proxima Nova, serif;
            font-style: normal;
            font-weight: normal;
            font-size: $font-12;
            line-height: $font-14;
            color: $marine-300;
            margin-bottom: $space-4;
        }

        .tooltip__value {
            font-family: Proxima Nova, serif;
            font-style: normal;
            font-size: $font-14;
            line-height: $font-16;
            color: $white;
        }
    }
</style>
