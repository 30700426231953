<template>
    <div>
        <mf-box>
            <div slot="header">
                Portcall Multiview
            </div>
            <div class="portcall-multiview" v-if="favouritePortcalls.portcalls.length">
                <div class="portcall-multiview__header">
                    <div class="portcall-multiview__items">
                        <mf-dropdown without-icon :disable="isLoading">
                            <div class="portcall-multiview__item" slot="link">
                                <mf-icon class="portcall-multiview__icon" icon-name="icon-filter" />
                                <div class="portcall-multiview__text">Filter</div>
                            </div>
                            <mf-box border-box without-inner-paddings>
                                <mf-checkbox
                                    slot="header"
                                    class="filter__checkbox"
                                    @change="toggleAll"
                                    :checked="allOptionsSelected"
                                >
                                    All filters
                                </mf-checkbox>
                                <div>
                                    <template v-for="status in options">
                                        <mf-divider :key="`divider-${status.name}`" />
                                        <div class="filter__item" :key="status.name">
                                            <mf-checkbox
                                                class="filter__checkbox"
                                                @change="status.checked = $event"
                                                :checked="status.checked"
                                            >
                                                <span
                                                    class="status__icon"
                                                    :class="`status__icon_${status.color}`"
                                                ></span>
                                                {{ formatOperation(status.name) }}
                                            </mf-checkbox>
                                        </div>
                                    </template>
                                    <mf-divider />
                                </div>
                            </mf-box>
                        </mf-dropdown>
                    </div>
                </div>
                <mf-divider />
                <mf-responsive-table>
                    <table class="portcall-multiview__table">
                        <tr class="portcall-multiview__labels">
                            <th class="portcall-multiview__th"></th>
                            <th
                                class="portcall-multiview__th"
                                v-for="operationKey in displayedOperations"
                                :key="operationKey"
                            >
                                <div class="portcall-multiview__label">
                                    {{ formatOperation(operationKey) }}
                                </div>
                            </th>
                        </tr>
                        <mf-divider />
                        <template v-for="(portcall, index) in displayedPortcalls">
                            <tr
                                v-if="displayedPortcalls.length === favouritePortcalls.portcalls.length"
                                class="portcall-multiview__line"
                                :key="portcall.portCallId"
                                :class="index % 2 && 'portcall-multiview__line_light'"
                            >
                                <td class="portcall-multiview__th">
                                    <portcall-item class="portcall-multiview__title" :portcall="portcall" />
                                </td>
                                <td
                                    class="portcall-multiview__th"
                                    v-for="operationKey in displayedOperations"
                                    :key="`value-${operationKey}`"
                                >
                                    <template v-if="portcall.maxEventsLength">
                                        <div
                                            class="portcall-multiview__value"
                                            v-for="index in portcall.maxEventsLength"
                                            :key="index"
                                        >
                                            <template v-if="portcall[operationKey][index - 1]">
                                                <event
                                                    v-if="isNotTimeStamps(operationKey)"
                                                    class="portcall-multiview__event-line"
                                                    :key="`event-${index - 1}`"
                                                    :event="portcall[operationKey][index - 1]"
                                                    @toggleModal="toggleModal"
                                                />
                                                <div
                                                    v-else
                                                    class="portcall-multiview__time-stamp-line"
                                                    @click="toggleModal(portcall[operationKey][index - 1])"
                                                >
                                                    <statement-icon
                                                        class="time-stamp__icon"
                                                        :type="portcall[operationKey][index - 1].lastStatement.timeType"
                                                        size="x-small"
                                                    />
                                                    {{
                                                        portcall[operationKey][index - 1].lastStatement.time
                                                            | dateFilter('date, time')
                                                    }}
                                                </div>
                                            </template>
                                            <div v-else class="portcall-multiview__value">
                                                _____
                                            </div>
                                        </div>
                                    </template>
                                    <div v-else class="portcall-multiview__value">
                                        _____
                                    </div>
                                </td>
                            </tr>
                            <mf-divider
                                v-if="displayedPortcalls.length === favouritePortcalls.portcalls.length"
                                :key="`divider-${portcall.portCallId}`"
                            />
                        </template>
                    </table>
                </mf-responsive-table>
                <div
                    v-if="isLoading && displayedPortcalls.length !== favouritePortcalls.portcalls.length"
                    class="portcall-multiview__loading"
                >
                    Wait loading portcalls info
                </div>
            </div>
            <div class="portcall-multiview__empty" v-else>
                <div>You don't have any favourite portcalls</div>
                <div>PortCall Multiview is used to compare different portcalls and get an overview</div>
            </div>
        </mf-box>
        <event-modal
            v-if="selectedEvent"
            :event="selectedEvent"
            :is-open="isModalShown"
            @close="toggleModal"
        ></event-modal>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import getInfoAboutPortcall from '@/helpers/getInfoAboutPortcall.helper';
    import PortcallItem from '@/components/port-call-components/PortcallItem';
    import Event from '@/components/timeline-components/Event';
    import EventModal from '@/modals/EventModal';
    import StatementIcon from '@/components/statement-components/StatementIcon';
    import capitalize from '@/filters/capitalize.filter';
    import localStorage from '@/services/local-storage/localStorage';
    import { cloneDeep } from 'lodash';

    const POSSIBLE_OPERATIONS = [
        'ARRIVAL_PORTAREA',
        'TOWAGE_OPERATION',
        'VESSEL_AT_ANCHORING_AREA',
        'PILOTAGE_OPERATION',
        'VESSEL_AT_BERTH',
        'CARGO_OPERATION',
        'BERTH_DEPARTURE',
    ];

    const CUSTOM_OPERATIONS_LABELS = {
        ARRIVAL_PORTAREA: 'Arrival Traffic Area',
        VESSEL_AT_ANCHORING_AREA: 'Anchoring Area',
        VESSEL_AT_BERTH: 'Berth Arrival',
    };

    const TIME_STAMPS = ['ARRIVAL_PORTAREA', 'VESSEL_AT_BERTH', 'BERTH_DEPARTURE'];

    const VESSEL_AT_BERTH_STATES = {
        VESSEL_AT_BERTH: 'Arrival Vessel Berth',
        BERTH_DEPARTURE: 'Departure Vessel Berth',
    };

    export default {
        name: 'PortcallMultiview',
        components: { EventModal, Event, PortcallItem, StatementIcon },
        computed: {
            ...mapGetters(['favouritePortcalls', 'statesDefinitions']),
            displayedOperations() {
                return this.options.filter(option => option.checked).map(option => option.name);
            },
            allOptionsSelected() {
                return this.options.every(option => option.checked);
            },
        },
        mounted() {
            this.$store.dispatch('getStatesDefinitions');

            const optionsFromStorage = localStorage.show('portcallMultiviewFilters');
            if (optionsFromStorage) {
                this.options = optionsFromStorage;
            }
        },
        watch: {
            favouritePortcalls: {
                async handler(val) {
                    this.isLoading = true;
                    for (let portcallId of val.portcalls) {
                        await this.formatPortcall(portcallId);
                    }
                    this.isLoading = false;
                },
                immediate: true,
            },
            options: {
                handler(val) {
                    val && localStorage.create('portcallMultiviewFilters', val);
                },
                deep: true,
            },
        },
        data() {
            const options = POSSIBLE_OPERATIONS.map(operation => {
                return { name: operation, checked: true };
            });
            return {
                displayedPortcalls: [],
                options,
                selectedEvent: {},
                isModalShown: false,
                isLoading: false,
            };
        },
        methods: {
            async formatPortcall(portcallId) {
                const portcall = await getInfoAboutPortcall(portcallId);

                let maxEventsLength = 0;
                POSSIBLE_OPERATIONS.forEach(option => {
                    const events = this.findNeededEvents(portcall, option);
                    maxEventsLength = Math.max(events.length, maxEventsLength);

                    if (option === 'VESSEL_AT_BERTH') {
                        for (const [key, value] of Object.entries(VESSEL_AT_BERTH_STATES)) {
                            const event = this.findLatestReportedTimeStamp(events, value);
                            portcall[key] = cloneDeep(event);
                        }
                    } else if (option !== 'BERTH_DEPARTURE') {
                        portcall[option] = TIME_STAMPS.includes(option)
                            ? this.findLatestReportedTimeStamp(events)
                            : events;
                    }
                });
                portcall['maxEventsLength'] = maxEventsLength;

                this.displayedPortcalls.push(portcall);
            },
            findNeededEvents(portcall, operationKey) {
                return portcall.events.filter(event => event.definitionId === operationKey);
            },
            findLatestReportedTimeStamp(events, stateDefinition = false) {
                let isSomeEventsStatements = false;

                if (events.length) {
                    events.forEach(event => {
                        let eventStatements = stateDefinition
                            ? event.statements.filter(state => {
                                  return state.stateDefinition === stateDefinition;
                              })
                            : event.statements;
                        if (!isSomeEventsStatements && eventStatements && eventStatements.length) {
                            isSomeEventsStatements = true;
                        }
                        const actualTimeStamps = eventStatements.filter(item => {
                            return item.timeType === 'Actual';
                        });
                        const estimatedTimeStamps = eventStatements.filter(item => {
                            return item.timeType === 'Estimated';
                        });

                        if (actualTimeStamps && actualTimeStamps.length) {
                            event.lastStatement = actualTimeStamps[actualTimeStamps.length - 1];
                        } else if (estimatedTimeStamps && estimatedTimeStamps.length) {
                            event.lastStatement = estimatedTimeStamps[estimatedTimeStamps.length - 1];
                        } else if (eventStatements && eventStatements.length) {
                            event.lastStatement = eventStatements[eventStatements.length - 1];
                        }
                    });
                }

                events = events.filter(event => {
                    return event.lastStatement;
                });

                return (isSomeEventsStatements && events) || [];
            },
            isNotTimeStamps(operationKey) {
                return !TIME_STAMPS.includes(operationKey);
            },
            formatOperation(operation) {
                return (
                    CUSTOM_OPERATIONS_LABELS[operation] ||
                    operation
                        .toLowerCase()
                        .split('_')
                        .map(capitalize)
                        .join(' ')
                );
            },
            toggleAll() {
                const currentState = this.allOptionsSelected;
                this.options.map(option => {
                    option.checked = !currentState;
                    return option;
                });
            },
            toggleModal(event) {
                if (event) {
                    this.selectedEvent = event;
                    this.isModalShown = true;
                } else {
                    this.isModalShown = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .portcall-multiview__table {
        width: 100%;
    }

    .portcall-multiview__header {
        margin-bottom: $space-20;
        display: flex;
        justify-content: flex-end;
    }

    .portcall-multiview__items {
        display: flex;
        align-items: center;
    }

    .portcall-multiview__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $white;
        font-size: $font-12;
        line-height: $font-14;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
        &:not(:first-child) {
            margin-left: $space-16;
        }
    }

    .portcall-multiview__item_selected {
        color: $blue-300;
    }

    .portcall-multiview__item_disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    .portcall-multiview__icon {
        margin-bottom: $space-8;
    }

    .portcall-multiview__filter {
    }

    .portcall-multiview__table {
        margin-top: $space-20;
        padding-bottom: $space-4;
        position: relative;
        th,
        tr,
        td {
            padding: 0;
        }
    }

    .portcall-multiview__title {
        font-weight: bold;
        font-size: $font-13;
        line-height: $font-18;
        display: flex;
        align-items: center;
        color: $white;
        opacity: 0.8;
        min-width: 348px;
        padding: $space-20 $space-8;
    }

    .portcall-multiview__value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 48px;
        margin-bottom: $space-6;
    }

    .portcall-multiview__event-line {
        padding: 8px 5px;
    }

    .portcall-multiview__time-stamp-line {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-14;
        margin-top: $space-16;
        cursor: pointer;
    }

    .time-stamp__icon {
        margin-right: $space-6;
    }

    .portcall-multiview__line {
        display: flex;
    }

    .portcall-multiview__line_light {
        background: $marine-600;
    }

    .portcall-multiview__labels {
        display: flex;
    }

    .portcall-multiview__th {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        min-width: 160px;
        color: $white;
        position: relative;
    }

    .portcall-multiview__th:first-child {
        min-width: 348px;
        flex: 0;
    }

    .portcall-multiview__label {
        font-size: $font-12;
        line-height: $font-18;
        border: 1px solid $marine-500;
        padding: 0 5px;
        border-top: transparent;
        height: 16px;
        flex: 1 1 auto;
    }

    .filter__item {
        padding: $space-14 $space-16;
    }

    .portcall-multiview__empty {
        margin-top: $space-50;
        text-align: center;
        font-size: $font-16;
        line-height: $font-20;
        color: $marine-400;
        min-height: 100px;
    }

    .portcall-multiview__loading {
        text-align: center;
        padding-top: 20px;
        color: $white;
    }
</style>
