import { Api } from '@/services/apiService';

class vivaStationsApi {
    static index() {
        return Api.get('', {
            baseURL: '/viva-stations-api',
        });
    }
    static show(id) {
        return Api.get(`${id}`, {
            baseURL: '/viva-stations-api',
        });
    }
}

export default vivaStationsApi;
