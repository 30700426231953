<template>
    <mf-box>
        <div slot="header">
            Port Overall Status
        </div>
        <div>
            <Highcharts class="port-overall__doughnut" :options="highchartsOptions" />
        </div>
    </mf-box>
</template>

<script>
    export default {
        name: 'PortOverallStatus',
        props: {
            options: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                loaded: true,
                highchartsOptions: {
                    border: 'none',
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        styledMode: true,
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                    },
                    title: '',
                    accessibility: {
                        point: {
                            valueSuffix: '%',
                        },
                    },
                    legend: {
                        layout: 'vertical',
                        y: -20,
                        itemMarginTop: 20,
                        enabled: true,
                        labelFormat: '<div class="label">{name}: <div>{percentage:.1f}% ({y})</div></div>',
                        useHTML: true,
                        navigation: { enabled: false },
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: false,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false,
                            },
                            showInLegend: true,
                        },
                        series: {
                            states: {
                                hover: {
                                    halo: {
                                        size: 0,
                                    },
                                },
                            },
                        },
                    },
                    series: [
                        {
                            innerSize: '25%',
                            name: 'Amount',
                            colorByPoint: true,
                            data: this.options,
                        },
                    ],
                    responsive: {
                        rules: [
                            {
                                condition: {
                                    minWidth: 440,
                                },
                                chartOptions: {
                                    legend: {
                                        align: 'right',
                                        verticalAlign: 'top',
                                    },
                                },
                            },
                        ],
                    },
                },
            };
        },
    };
</script>

<style lang="scss" scoped>
    .port-overall__doughnut {
        @include media($sm) {
            height: 230px;
        }
        @include media($xlg) {
            height: 280px;
        }
    }

    /deep/ {
        .label {
            display: flex;
            justify-content: space-between;
            min-width: 200px;
        }

        .highcharts-container {
            position: relative;
        }

        .highcharts-background,
        .highcharts-plot-background,
        .highcharts-plot-border,
        .highcharts-legend-box {
            fill: transparent;
        }

        .highcharts-color-0 {
            fill: $green-500;
            stroke: transparent;
        }

        .highcharts-color-1 {
            fill: $orange-500;
            stroke: transparent;
        }

        .highcharts-color-2 {
            fill: $red-500;
            stroke: transparent;
        }

        .highcharts-color-3 {
            fill: #7bb4ec;
            stroke: transparent;
        }

        .highcharts-color-4 {
            fill: $marine-500;
            stroke: transparent;
        }

        .highcharts-color-5 {
            fill: $marine-600;
            stroke: transparent;
        }

        .highcharts-legend-item {
            cursor: pointer;
            font-family: 'Proxima Nova', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            opacity: 1 !important;
            &:hover {
                opacity: 0.7 !important;
            }
        }

        .highcharts-legend-item-hidden {
            opacity: 0.3 !important;
        }

        .highcharts-tooltip {
            fill: $white;
            cursor: pointer;
            font-family: 'Proxima Nova', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            opacity: 1 !important;
        }

        .highcharts-point-hover {
            opacity: 0.7;
        }

        .highcharts-tooltip-box {
            fill: $marine-600;
        }

        .highcharts-credits {
            display: none;
        }
    }
</style>
